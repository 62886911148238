import { AIType, ChatStatus, type IChat, type ICredit, type ISetHistory } from '@/utils/jDroid'
import { type ILanguage } from '@/utils/sharedData/languages'

import gtmService from '@/services/gtm.service'
import type { IJDAnswer } from '@/services/supbase.service'
import { useIdeStore } from '@/stores/ide.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useJdroidStore = defineStore('jdroid', () => {
  const ssgJdroidAllQuestion = ref<IJDAnswer[]>([])

  const isJdroidChatView = ref<boolean>(false)

  const showJdroid = ref<boolean>(false)

  const chatID = ref<string | null>(null)
  const chatList = ref<IChat[]>([])
  const lastChat = ref<IChat | null>(null)

  const showNotification = ref<boolean>(false)
  const notificationKeyCount = ref<number>(0)

  const getMoreCreditsUpdated = ref<boolean>(false)
  const startCodingLanguage = ref<ILanguage | null>(null)
  const startCoadingVersion = ref<string | null>(null)
  const startCoadingEmpty = ref<boolean>(false)

  const quota = ref<number>(0)
  const used = ref<number>(0)

  /**
   * Set JDroid chat view
   * @param value boolean
   */
  const setIsJdroidChatView = (value: boolean) => {
    isJdroidChatView.value = value
  }

  const isLastChatLoading = computed(() => {
    // is last chat status is loading
    if (lastChat.value !== null) {
      return (
        lastChat.value?.status === ChatStatus.LOADING ||
        lastChat.value?.status === ChatStatus.STREAMING
      )
    } else {
      return false
    }
  })
  const isLastChatSuccess = computed(() => {
    // is last chat status is loading
    if (lastChat.value !== null) {
      return lastChat.value.status === ChatStatus.SUCCESS
    } else {
      return false
    }
  })
  const isLastChatExplainError = computed(() => {
    // is last chat status is loading
    if (lastChat.value !== null) {
      return lastChat.value.type === AIType.EXPLAINERROR
    } else {
      return false
    }
  })

  const showGetMoreCredits = computed(() => {
    return (
      useOrganisationStore().isOrganisationOwner ||
      useOrganisationStore().isOrganisationAdmin ||
      useOrganisationStore().isIndividualAccount
    )
  })

  /**
   * Set SSGJdroidAllQuestion
   * @param value IJDAnswer[]
   */
  const setSSGJdroidAllQuestion = (value: IJDAnswer[]) => {
    ssgJdroidAllQuestion.value = value
  }

  /**
   * toggle showJdroid
   * @param value boolean
   */
  const setShowJdroid = (value: boolean) => {
    showJdroid.value = value
  }
  /**
   * set chatID
   * @param value string | null
   */
  const setChatID = (value: string | null) => {
    chatID.value = value
  }

  /**
   * add chat to chatList
   * @param chat IChat
   */
  const addChat = (chat: IChat) => {
    chatList.value.forEach((item) => {
      if (item.status === ChatStatus.ERROR) removeChat(item)
    })
    chatList.value.push(chat)

    if (useIdeStore().isIdeView) gtmService.sentEvent({ event: 'jdroid_queries' })
    lastChat.value = chatList.value[chatList.value.length - 1]
  }
  /**
   * Remove chat to chatList
   * @param chat IChat
   */
  const removeChat = (chat: IChat) => {
    if (chat.status === ChatStatus.ERROR) {
      chatList.value.pop()
      lastChat.value = chatList.value[chatList.value.length - 1]
    }
  }
  /**
   * clear chatList
   */
  const clearChat = () => {
    lastChat.value = null
    chatList.value = []
  }
  /**
   * set showNotification
   * @param value boolean
   */
  const setShowNotification = (value: boolean) => {
    showNotification.value = value
  }
  /**
   * set notificationKeyCount
   * @param value number
   */
  const setNotificationKeyCount = (value: number) => {
    notificationKeyCount.value = value
  }
  /**
   * set more credits updated
   * @param value number
   */
  const setMoreCreditsUpdated = (value: boolean) => {
    getMoreCreditsUpdated.value = value
  }
  /**
   * Set the start coding language
   * @param lang - The language to set
   */
  const setStartCodingLanguage = (lang: ILanguage | null) => {
    startCodingLanguage.value = lang
  }
  /**
   * Set the start coding version
   * @param version - The version to set
   */
  const setStartCoadingVersion = (version: string | null) => {
    startCoadingVersion.value = version
  }
  /**
   * Set the start coding empty
   * @param value - The value to set
   */
  const setStartCoadingEmpty = (value: boolean) => {
    startCoadingEmpty.value = value
  }

  /**
   * Get the last five formatted chat
   * @returns Array<[string, string]>
   */
  const getLastFiveFormattedChat = () => {
    try {
      const chatListWithoutLastChat = (chatList.value.slice(0, -1) as IChat[]) || []

      const lastFiveChats = (chatListWithoutLastChat.slice(-5) as IChat[]) || []

      const formatedLastFiveChats =
        (lastFiveChats.map((chat) => {
          return [chat.prompt, chat.response]
        }) as Array<[string, string]>) || []
      return formatedLastFiveChats
    } catch (error) {
      return []
    }
  }

  /**
   * Get the last five chat ai payload history
   * @returns ISetHistory[]
   */
  const getLastFiveChatAiPayloadHistory = (): ISetHistory[] => {
    try {
      const chatListWithoutLastChat = (chatList.value.slice(0, -1) as IChat[]) || []

      const lastFiveChats = (chatListWithoutLastChat.slice(-5) as IChat[]) || []

      const formatedLastFiveHistory =
        (lastFiveChats.map((chat) => {
          return {
            aiPayload: chat.aiPayload || '',
            answer: chat.response || ''
          } as ISetHistory
        }) as Array<ISetHistory>) || []
      return formatedLastFiveHistory
    } catch (error) {
      return []
    }
  }
  /**
   * Set used credits
   * @param credit ICredit
   */
  const setCredits = (credit: ICredit) => {
    quota.value = credit.quota
    used.value = credit.used
  }

  return {
    ssgJdroidAllQuestion,
    setSSGJdroidAllQuestion,
    isJdroidChatView,
    setIsJdroidChatView,
    showJdroid,
    setShowJdroid,
    chatID,
    setChatID,
    chatList,
    addChat,
    clearChat,
    lastChat,
    isLastChatLoading,
    isLastChatSuccess,
    isLastChatExplainError,
    showNotification,
    setShowNotification,
    notificationKeyCount,
    setNotificationKeyCount,
    removeChat,
    getMoreCreditsUpdated,
    setMoreCreditsUpdated,
    startCodingLanguage,
    setStartCodingLanguage,
    startCoadingVersion,
    setStartCoadingVersion,
    startCoadingEmpty,
    setStartCoadingEmpty,
    showGetMoreCredits,
    quota,
    used,
    setCredits,
    getLastFiveFormattedChat,
    getLastFiveChatAiPayloadHistory
  }
})
