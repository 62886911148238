import tabsService from '@/services/ide/tabs.service'
import { TAB_BUTTON_POSITION, TAB_ICONS_NAMES, TABS_WIDTH_TYPE } from '@/utils/tabs'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useIdeStore } from './ide.store'
export interface ActiveTab {
  visible: boolean
  tabName: string
  position: TAB_BUTTON_POSITION
  previousPosition?: TAB_BUTTON_POSITION
}

interface TabWidthSize {
  tabName: TAB_ICONS_NAMES | null
  small: boolean
  medium: boolean
  large: boolean
}

interface ActiveTabPosition {
  activeTab: ActiveTab[]
}

interface TabSettings {
  visible: boolean
  position: string
}

export const defaultActiveTabs: ActiveTab[] = [
  {
    tabName: TAB_ICONS_NAMES.EXTERNAL_LIBRARIES,
    visible: false,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  },
  {
    tabName: TAB_ICONS_NAMES.UPLOAD_FILES,
    visible: false,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  },
  {
    tabName: TAB_ICONS_NAMES.IO,
    visible: false,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  },
  {
    tabName: TAB_ICONS_NAMES.JDROID,
    visible: true,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  },
  {
    tabName: TAB_ICONS_NAMES.BROWSER,
    visible: false,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  },
  {
    tabName: TAB_ICONS_NAMES.API_TAB,
    visible: false,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  },
  {
    tabName: TAB_ICONS_NAMES.DB_TERMINAL,
    visible: false,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  },
  {
    tabName: TAB_ICONS_NAMES.TERMINAL,
    visible: false,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  },
  {
    tabName: TAB_ICONS_NAMES.CONSOLE,
    visible: false,
    position: TAB_BUTTON_POSITION.RIGHT,
    previousPosition: TAB_BUTTON_POSITION.RIGHT
  }
]

export const useTabStore = defineStore('tabs', () => {
  const activeTabPosition = ref<ActiveTabPosition>({
    activeTab: defaultActiveTabs
  })

  const ioTabVisible = ref<boolean>(false)

  const isTabsUpdating = ref<boolean>(false)

  const isTabMaximized = ref<boolean>(false)

  const openTabSettings = ref<TabSettings>({
    visible: false,
    position: ''
  })

  const tabWidthCurrentSize = ref<TabWidthSize>({
    tabName: null,
    small: false,
    medium: false,
    large: true
  })

  const tabWidthCurrentSizeBottom = ref<TabWidthSize>({
    tabName: null,
    small: false,
    medium: false,
    large: true
  })

  /**
   * Sets the status of the tab icon for a specific tab.
   * @param position - position to move tab to
   * @param tabName The name of the tab to update or add.
   */
  const setActiveTabPosition = (position: TAB_BUTTON_POSITION, tabName: TAB_ICONS_NAMES) => {
    const index = activeTabPosition.value.activeTab.findIndex((item) => item.tabName === tabName)

    if (index !== -1) {
      activeTabPosition.value.activeTab[index].previousPosition =
        activeTabPosition.value.activeTab[index].position
      const currentStatus = activeTabPosition.value.activeTab[index].visible
      activeTabPosition.value.activeTab[index].position = position

      if (
        activeTabPosition.value.activeTab[index].position ==
        activeTabPosition.value.activeTab[index].previousPosition
      ) {
        activeTabPosition.value.activeTab[index].visible = !currentStatus
      } else {
        if (activeTabPosition.value.activeTab[index].position == TAB_BUTTON_POSITION.MAXIMIZE) {
          activeTabPosition.value.activeTab[index].visible = true
        }
      }

      activeTabPosition.value.activeTab.forEach((item) => {
        if (item.position == position && item.tabName != tabName) {
          item.visible = false
        }
      })
    } else {
      const currentStatus = false
      activeTabPosition.value.activeTab.push({ position, tabName, visible: !currentStatus })
    }
  }

  /**
   * Sync tab position values in pinia
   * @param value is new
   */
  const syncActiveTabPosition = (value: ActiveTabPosition) => {
    activeTabPosition.value = value
  }

  /**
   * Updates the default tab position with the db tab data.
   * @param data - The array of tab objects to transform and update.
   */
  const updateDefaultTabPosition = (data: ActiveTab[]) => {
    const filterHtmlData =
      data.length > 0 &&
      data.filter(
        (item) => item.tabName == TAB_ICONS_NAMES.BROWSER || item.tabName == TAB_ICONS_NAMES.JDROID
      )

    if (useIdeStore().isHtml) {
      activeTabPosition.value.activeTab =
        filterHtmlData && filterHtmlData.length > 0
          ? (filterHtmlData as ActiveTab[])
          : defaultActiveTabs
    } else {
      activeTabPosition.value.activeTab =
        data.length > 0 ? (data as ActiveTab[]) : defaultActiveTabs
    }
  }

  /**
   * update the current tab positions array from local storage
   * @param tabsPosition current tab positions array
   */
  const updateTabPositionFromLocalStorage = (tabsPosition: ActiveTab[]) => {
    activeTabPosition.value.activeTab = tabsPosition || defaultActiveTabs
  }

  /**
   * this function toggle pinia tabs values
   * @param onbeforeMount onbeforeMount flag used to called the function on intial mount
   * @param isUserloggedIn isUserloggedIn value
   */
  const setNonDatabaseTabValues = async (
    onbeforeMount: boolean = false,
    isUserloggedIn: boolean
  ) => {
    isTabsUpdating.value = true
    let value: ActiveTab[]

    if (isUserloggedIn) {
      value = ((await tabsService.getTabPositions()) as ActiveTab[]) || []
    } else {
      value = (tabsService.getTabPositionFromLocalStorage() as ActiveTab[]) || []
    }

    value = Array.isArray(value) ? value : []

    const tabs = activeTabPosition?.value.activeTab.map((item) => ({ ...item }))

    const newTabs: ActiveTab[] = []
    if (value.length > 0) {
      value.forEach((item) => {
        if (item.tabName != TAB_ICONS_NAMES.DB_TERMINAL) {
          newTabs.push(item)
        }
      })
    } else {
      defaultActiveTabs?.forEach((item) => {
        if (item.tabName != TAB_ICONS_NAMES.DB_TERMINAL) {
          newTabs.push(item)
        }
      })
    }

    if (onbeforeMount) {
      value?.forEach((item) => {
        if (item?.tabName == TAB_ICONS_NAMES.DB_TERMINAL) {
          item.visible = false
          newTabs.push(item)
        }
      })
    } else {
      tabs?.forEach((item) => {
        if (item?.tabName == TAB_ICONS_NAMES.DB_TERMINAL) {
          item.visible = false
          newTabs.push(item)
        }
      })
    }

    activeTabPosition.value.activeTab = newTabs
    isTabsUpdating.value = false
  }

  /**
   * this function toggle pinia tabs values
   * @param onbeforeMount onbeforeMount flag used to called the function on intial mount
   * @param isUserloggedIn isUserloggedIn value
   */
  const setDatabaseTabValues = async (onbeforeMount: boolean = false, isUserloggedIn: boolean) => {
    isTabsUpdating.value = true
    let value: ActiveTab[]
    if (isUserloggedIn) {
      value = ((await tabsService.getTabPositions()) as ActiveTab[]) || []
    } else {
      value = (tabsService.getTabPositionFromLocalStorage() as ActiveTab[]) || []
    }

    value = Array.isArray(value) ? value : []

    const tabs = activeTabPosition?.value.activeTab.map((item) => ({ ...item }))

    const newTabs: ActiveTab[] = []
    if (value.length > 0) {
      value?.forEach((item) => {
        if (item.tabName == TAB_ICONS_NAMES.DB_TERMINAL) {
          newTabs.push(item)
        }
      })
    } else {
      defaultActiveTabs.forEach((item) => {
        if (item.tabName != TAB_ICONS_NAMES.DB_TERMINAL) {
          newTabs.push(item)
        }
      })
    }

    if (onbeforeMount) {
      value?.forEach((item) => {
        if (item?.tabName === TAB_ICONS_NAMES.JDROID) {
          const dbTerminalTab = value?.find((val) => val.tabName === TAB_ICONS_NAMES.DB_TERMINAL)

          if (item.visible && dbTerminalTab?.visible) {
            item.visible = false
          }
          newTabs.push(item)
        } else if (item.tabName != TAB_ICONS_NAMES.DB_TERMINAL) {
          item.visible = false
          newTabs.push(item)
        }
      })
    } else {
      tabs?.forEach((item) => {
        if (item.tabName === TAB_ICONS_NAMES.JDROID) {
          const dbTerminalTab = value?.find((val) => val.tabName === TAB_ICONS_NAMES.DB_TERMINAL)
          const jdroidTab = value?.find((val) => val.tabName === TAB_ICONS_NAMES.JDROID)

          // If the Database Terminal tab is visible and in the same position, hide the Jdroid tab
          if (jdroidTab?.visible == dbTerminalTab?.visible) {
            item.visible = false
          } else {
            item.visible = jdroidTab?.visible || false
            TAB_BUTTON_POSITION.BOTTOM
          }
          newTabs.push(item)
        } else if (item.tabName !== TAB_ICONS_NAMES.DB_TERMINAL) {
          // Make other tabs invisible except the Database Terminal tab
          item.visible = false
          newTabs.push(item)
        }
      })
    }

    activeTabPosition.value.activeTab = newTabs
    isTabsUpdating.value = false
  }

  /**
   * Sets the visibility of the IO tab.
   *
   * Updates the `ioTabVisible` reactive value to control whether the IO tab is visible.
   * @param  val - `true` to make the IO tab visible, `false` to hide it.
   */
  const setIOTabVisible = (val: boolean) => {
    ioTabVisible.value = val
  }

  /**
   * Sets the maximized state of the tab.
   *
   * Updates the `isTabMaximized` reactive value to indicate whether the tab is maximized.
   * @param  value - `true` to maximize the tab, `false` to unmaximize it.
   */
  const setTabMaximized = (value: boolean) => {
    isTabMaximized.value = value
  }

  /**
   * Sets the visibility and position of the open tab settings.
   * This function updates the `openTabSettings` reactive object with the provided
   * visibility and position values.
   * @param value - An object containing the
   *   visibility and position settings for the tab.
   * @param  value.visible - Determines if the tab should be visible.
   * @param  value.position - The position of the tab (e.g., "left", "right").
   */
  const setTabSettings = (value: { visible: boolean; position: string }) => {
    openTabSettings.value.visible = value.visible
    openTabSettings.value.position = value.position
  }
  /**
   * Updates the `tabWidthCurrentSize` value based on the specified tab width type.
   * @param type - The type of tab width to set.
   * @param tabName - current tabName
   */
  const setTabWidthCurrentSize = (type: TABS_WIDTH_TYPE, tabName: TAB_ICONS_NAMES | null) => {
    if (tabWidthCurrentSize.value !== null) {
      switch (type) {
        case TABS_WIDTH_TYPE.LARGE:
          tabWidthCurrentSize.value = {
            tabName: tabName as TAB_ICONS_NAMES,
            large: true,
            medium: false,
            small: false
          }
          break
        case TABS_WIDTH_TYPE.MEDIUM:
          tabWidthCurrentSize.value = {
            tabName: tabName as TAB_ICONS_NAMES,
            large: false,
            medium: true,
            small: false
          }
          break
        default:
          tabWidthCurrentSize.value = {
            tabName: tabName as TAB_ICONS_NAMES,
            large: false,
            medium: false,
            small: true
          }

          break
      }
    }
  }
  /**
   * Updates the `tabWidthCurrentSize` value based on the specified tab width type.
   * @param type - The type of tab width to set.
   * @param tabName - current tabName
   */
  const setTabWidthCurrentSizeBottom = (type: TABS_WIDTH_TYPE, tabName: TAB_ICONS_NAMES | null) => {
    if (tabWidthCurrentSizeBottom.value !== null) {
      switch (type) {
        case TABS_WIDTH_TYPE.LARGE:
          tabWidthCurrentSizeBottom.value = {
            tabName: tabName as TAB_ICONS_NAMES,
            large: true,
            medium: false,
            small: false
          }
          break
        case TABS_WIDTH_TYPE.MEDIUM:
          tabWidthCurrentSizeBottom.value = {
            tabName: tabName as TAB_ICONS_NAMES,
            large: false,
            medium: true,
            small: false
          }
          break
        default:
          tabWidthCurrentSizeBottom.value = {
            tabName: tabName as TAB_ICONS_NAMES,
            large: false,
            medium: false,
            small: true
          }

          break
      }
    }
  }

  return {
    activeTabPosition,
    setActiveTabPosition,
    syncActiveTabPosition,
    updateTabPositionFromLocalStorage,
    updateDefaultTabPosition,
    setIOTabVisible,
    setTabMaximized,
    setNonDatabaseTabValues,
    setDatabaseTabValues,
    isTabMaximized,
    ioTabVisible,
    isTabsUpdating,
    openTabSettings,
    setTabSettings,
    tabWidthCurrentSize,
    setTabWidthCurrentSize,
    setTabWidthCurrentSizeBottom,
    tabWidthCurrentSizeBottom
  }
})
